@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  max-width: 200px;

  @include min-1440-break {
    max-width: 278px;
  }
}
