@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.wrapper {
  @include mainContainerStyles;
  max-width: 100%;
  display: flex;
  align-items: center;
  @include containerPaddings;
}

.shop-mobile {
  @include mainContainerStyles;
}
